import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import {
  useTheme,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PseudoBox, Text, List, ListItem, ListIcon, Icon, Heading, Box, Button, Divider
} from '@chakra-ui/core'

import AuthContext from 'contexts/AuthContext'
import { uid } from 'react-uid'
import { CHECKOUT_ROUTE, REGISTER_ROUTE } from 'config/routes'

const Feature = ({ name, value }) => {
  return (
    <ListItem>
      {value
        ? <ListItem icon="check" color="blue"/> : null }
      <b>{name}</b>{value}

    </ListItem>
  )
}
Feature.propTypes = {
  name: PropTypes.string.isRequired
}

const PricingCard = ({ plan, focus, period }) => {
  const { cost, emphasis, description, tier, cta, title, features } = plan

  const { setPostAuthPayload, setPostAuthRoute } = useContext(AuthContext)

  // const dispatch = useContext(DispatchContext)

  const handleClick = () => {
    // e.preventDefault()
    // setPostAuthPayload({ tier })
    setPostAuthRoute(CHECKOUT_ROUTE)
    // dispatch({ type: SET_PLAN, task: tier })

    navigate(REGISTER_ROUTE)
    // navigate(`${CHECKOUT_ROUTE}/${tier}`)
  }
  return (
    <Box
      // flexDirection= "column"

      borderRadius= {5}
      minWidth= "256px"
      textAlign= "center"
      background= "#FFF"
      borderColor={focus ? 'primary.500' : 'gray.200' }
      // borderColor="#E1E4EC"
      borderWidth={focus ? 3 : 1}
      boxShadow={focus ? '-2px 6px 30px 2px rgba(122,122,122,.5);' : 'none'}
      height="100%"
      p={10}

    >
      <Heading
        as="h3" size="lg"
        textAlign="center"
        // fontSize: '1.6em',
        // fontWeight: 700,

      >{ title }</Heading>
      {/* <Divider borderColor="silver" mx={4} /> */}
      <Box flexGrow={1}>

        <Box mt={3} h="80px" lineHeight="80px">
          {cost
            ? cost.monthly === 0
              ? <Text fontSize="35px" fontWeight="600">Free</Text>
              : <PseudoBox fontSize="50px" position="relative" _before={{ content: '"$"', fontSize: '30px', top: '-13px', position: 'relative', left: '0px' }} fontWeight="600">{cost[period]}</PseudoBox>
            : <Text fontSize="30px" fontWeight="600">Contact us</Text>}

        </Box>
        <Text h="20px" fontStyle="italic" mt={-3} color="gray.500" fontSize="sm">
          {parseInt(cost[period]) > 0 ? `billed ${period}` : null } </Text>
        <Text my={4} color="gray.500" h={50} fontSize="md">
          {description}
        </Text>

        <List mt={4} ml={0} spacing={3} textAlign="center">
          {features.map(f => {
            // const IconComponent = icons[f.icon] || icons.help

            return (

              <ListItem fontSize="md" fontWeight={f.emphasis ? '700' : '500' } key={uid(f)}>
                {/* <ListIcon icon="check" color="primary.500" /> */}
                {f.title} {f.tooltip ? <Popover trigger="hover" placement="top">
                  <PopoverTrigger>
                    <Icon ml={2} color="gray.500" name="info-outline" />
                  </PopoverTrigger>
                  <PopoverContent color="white"
                    bg="gray.800"
                    // borderColor="blue.800"
                  >
                    <PopoverArrow />
                    {/* <PopoverCloseButton /> */}
                    {/* <PopoverHeader>Confirmation!</PopoverHeader> */}
                    <PopoverBody>{f.tooltip}</PopoverBody>
                  </PopoverContent>
                </Popover> : null }
              </ListItem>
            )
          })}

        </List>

      </Box>

      <Divider my={10} variant="middle" />

      <Button
        size="lg"
        onClick={() => handleClick()}
        // to={
        //   cost
        //     ? `${REGISTER_ROUTE}?action=checkout&plan=${code}`
        //     : `${CONTACT_ROUTE}?topic=sales`
        // }
        variant={emphasis ? 'solid' : 'outline' }
        variantColor="primary"
      >
        {cta}
      </Button>

    </Box>
  )
}

PricingCard.defaultProps = {
  focus: false
}

PricingCard.propTypes = {
  focus: PropTypes.bool,
  plan: PropTypes.object.isRequired,
  period: PropTypes.string.isRequired
}

export default PricingCard
