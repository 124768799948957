export default [
  {
    title: 'Sandbox',
    active: true,
    plan: 'sub_123',
    tier: 'sandbox',
    cost: {
      monthly: 0,
      annually: 0
    },
    cta: 'Join for free',
    description: 'Get familiar with LotusEngine',
    features: [
      {
        title: '1000 invocations',
        icon: 'check'
      },
      {
        title: '100M storage',
        icon: 'storage'
      },
      {
        title: '2 workflows',
        icon: 'workflow'
      },
      {
        title: '1 user',
        icon: 'user'
      },
      {
        title: 'Basic support',
        icon: 'help'
      }
    ]
  },

  {
    title: 'Startup',
    active: true,
    tier: 'startup',
    plan: 'prod_Gt00jh9msdJ8OG',
    // plan: 'plan_Gt01o6BfS5vMcp',
    cost: {
      monthly: 149,
      annually: 1490
    },
    emphasis: true,
    cta: 'Signup now',
    description: 'Perfect for small teams',
    features: [
      {
        title: '10K invocations',
        icon: 'check'
      },
      {
        title: '1G storage',
        icon: 'storage'
      },
      {
        title: '100 workflows',
        icon: 'workflow'
      },
      {
        title: '5 users',
        icon: 'user'
      },
      {
        title: 'Email & chat support',
        icon: 'help'
      }
    ]
  },
  {
    title: 'Enterprise',
    active: true,
    tier: 'enterprise',
    prod: 'prod_Gt03Scr3CBCZ2E',
    plan: 'plan_Gt03EyklaRT1nF',
    cost: {
      monthly: 499,
      annually: 4900
    },
    cta: 'Signup now',

    description: 'Built for large scale operations',
    features: [
      // {
      //   title: 'All Standard features',
      //   emphasis: true
      // },
      {
        title: '50K invocations',
        icon: 'check'
        // tooltip: 'This is something!'
      },
      {
        title: '50G storage',
        icon: 'storage'
      },
      {
        title: '1000 workflows',
        icon: 'workflow'
      },
      {
        title: '100 users',
        icon: 'user'
      },
      {
        title: 'Dedicated support',
        icon: 'help'
      }
    ]
  }

  // {
  //   active: true,
  //   title: 'Enterprise',
  //   tier: 'enterprise',
  //   cta: 'Contact sales',
  //   description: 'Basically whatever you want',
  //   features: []
  //   //features: ['Something', 'Another thing', 'This and that']
  // }
]
