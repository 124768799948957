import React, { useState } from 'react'
import { Box, SimpleGrid, Text, Divider, Radio, RadioGroup, List, ListIcon, ListItem } from '@chakra-ui/core'
import Title from 'elements/Typography/Title'
import Emphasis from 'elements/Typography/Emphasis'
import Subtitle from 'elements/Typography/Subtitle'
import Subtle from 'elements/Typography/Subtle'
import PricingCard from 'elements/Plan/PricingCard'
import plans from 'config/plans'
import { uid } from 'react-uid'

export default function PricingTable (props) {
  const [period, setPeriod] = useState('monthly')
  return (
    <>
      <RadioGroup my={8} defaultValue={period} spacing={5} isInline textAlign="center">
        <Radio size="md" value="monthly" onClick={() => setPeriod('monthly')}>
        Monthly
        </Radio>
        <Radio size="md" value="annually" onClick={() => setPeriod('annually')}>
          Annually <Text as="span" color="primary.500">(Save over 15%)</Text>
        </Radio>
      </RadioGroup>
      <SimpleGrid mt={8} columns={[1, null, null, 3]} spacing="4">
        {plans.map((p, i) => (
          <PricingCard key={uid(p)} focus={i === 1} plan={p} period={period} />
        ))}
      </SimpleGrid>

      {/* <Divider mt="5"/>
      <Subtitle>How we measure usage:</Subtitle>
      <List mt={4} spacing={3}>
        <ListItem>
          <ListIcon icon="workflow" mb={1} color="gray.500" />
          <Emphasis>Invocations</Emphasis>: An invocation represents one workflow action of 100ms duration (most actions do not exceed this unless they are querying a 3rd party API)
        </ListItem>
        <ListItem>
          <ListIcon icon="collection" mb={1} color="gray.500" />
          <Emphasis>Storage</Emphasis>: We measure storage by the total data stored in collections over the billing period.
        </ListItem>
        <ListItem >
          <ListIcon icon="ui" mb={1} color="gray.500" />
          <Emphasis>Requests</Emphasis>: Each page view or API call is a request.
        </ListItem>
      </List> */}
    </>)
}
